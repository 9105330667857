@import url("https://fonts.googleapis.com/css2?family=Wix+Madefor+Text&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Wix+Madefor+Text:ital,wght@0,400;0,500;0,700;0,800;1,700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  color: #323232;
  font-family: "Wix Madefor Text", sans-serif;
  background: #e1ebf6;
}

.btn:hover {
  background: #2e5090;
  border-color: #2e5090;
}
